import React from 'react';

import {
  Footer,
  useTemplate,
  TimeoutModal,
  PrimaryButton,
  AccessibilityButton,
  AccessibilityWrapper,
} from '@fingermarkglobal/cringer.components';

import { Header } from '../../components/stateless/header';

import Logo from '../../assets/images/PizzaHutLogo.png';
import CartIconImage from '../../assets/images/cart-icon.svg';

const LogoImage = () => <img alt="logo" src={Logo} className="absolute top-0 w-auto h-64 mt-32" />;

const CustomDefaultTemplate = ({
  children,
  footerEnabled = true,
  headerEnabled = true,
  timeoutEnabled = true,
  templateOverride = false,
  showCategoriesHeader = false,
  showAccessibilityButton = true,
}) => {
  const {
    header,
    footer,
    isOnline,
    isCouponEnabled,
    isTimeoutEnabled,
    accessibilityEnabled,
    timeoutActionOverride,
    modalAccessibilityClass,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
    footerEnabled,
  });

  return (
    <div className="absolute bottom-0 w-full h-full overflow-y-hidden bg-black">
      <AccessibilityWrapper
        className="bg-default bg-cover md:bg-auto"
        LogoImage={LogoImage}
        isAccessibilityButtonEnabled={showAccessibilityButton}
      >
        <div className="flex flex-col w-full h-full">
          {headerEnabled && (
            <Header
              {...{ ...header, showCategoriesHeader }}
              isLogoEnabled={true}
              isCouponEnabled={isCouponEnabled}
              accessibilityEnabled={accessibilityEnabled}
            />
          )}

          <div className="flex flex-col h-full overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              OverwriteCartIcon={CartIconImage}
              accessibilityEnabled={accessibilityEnabled}
              CustomSecondaryButton={PrimaryButton}
              showAccessibilityButton={showAccessibilityButton}
            />
          )}

          {showAccessibilityButton && (
            <div className="flex flex-col items-start justify-center">
              <AccessibilityButton className="z-10 absolute bottom-0 hidden w-24 h-20 m-8 mt-8 mb-10 bg-white shadow-md md:block" />
            </div>
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
